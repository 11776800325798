import React from 'react';

import NewSectionLayoutGrid from "../layouts/NewSectionLayoutGrid";
import ListCard from "../cards/ListCard";
import CtaCard from "../cards/CTACard";
import NewCard from "../cards/NewCard";

import Col, {DesktopCol} from "../styled/grid/Col";
import {Header2New} from "../styled/typography/Typography";
import {Img, MovedCol} from "../styled/sections/sectionComponents";

const ApiLandingMiddleSection = ({cards, title, list, illustration, cta}) => {
    return (
        <NewSectionLayoutGrid id={"middle-section"} customPadding={"middle_section"}>
            {cards && cards.map((item, index) => (
                <Col span={3} key={index} grid marginTop={"small"} marginBottom={"normal"}>
                    <NewCard
                        title={item.title}
                        icon={item.icon.url}
                        description={item.description}
                    />
                </Col>
            ))}
            <Col marginTop={"largest"} marginBottom={"small"}>
                <Header2New light={"true"} color={"secondary-brand"}>{title}</Header2New>
            </Col>
            <Col span={8} grid gap marginBottom={"large"}>
                {list && list.map((item, index) => (
                    <ListCard
                        key={index}
                        number={index + 1}
                        item={item.item}
                    />
                ))}
            </Col>
            <DesktopCol span={4} grid marginBottom={"large"}>
                <Img src={illustration} alt={'illustration'}/>
            </DesktopCol>
            <MovedCol grid>
                <CtaCard
                    title={cta.title}
                    button_text={cta.button_text}
                />
            </MovedCol>
        </NewSectionLayoutGrid>
    );
};

export default ApiLandingMiddleSection;