import styled from "styled-components";

export const NewCardContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.025), 0 10px 20px rgba(54, 69, 104, 0.05);
  border-radius: 3px;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  
  img{
    width: 1.5rem;
    height: 1.5rem;
  }

  h3{
    width: 14ch;
  }
  
  @media (max-width: 800px){
    gap: 15px;
    h3{
      width: 100%;
    }
  }
`