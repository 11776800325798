import styled from "styled-components";

export const ListCardContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  padding: 20px;
  gap: 30px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.025), 0px 10px 20px rgba(54, 69, 104, 0.05);
  border-radius: 3px;
  
  div{
    width: 30px;
    height: 30px;

    background: #5B8DFF;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */
    text-align: center;

    color: #FFFFFF;
  }
`