import styled from "styled-components";

export const CtaCardContainer = styled.div`
  background: linear-gradient(279.58deg, #2860E1 0.77%, #5588FF 100%);
  border-radius: 3px;
  padding: 60px 10px;
  text-align: center;
  display: grid;
  justify-content: center;
  grid-gap: 1rem 0;
`