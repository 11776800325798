import React from 'react';
import {Link} from "gatsby";

import NewSectionLayoutGrid from "../layouts/NewSectionLayoutGrid";

import {NavbarLogo} from "../styled/layouts/NavbarComponents";
import Col from "../styled/grid/Col";
import {BodyNew} from "../styled/typography/Typography";

import logo from "../../../static/assets/navbar/balasys_logo_dark_horizontal.svg";
import styled from "styled-components";

const CenterLink = styled(Link)`
  place-self: center;
`

const ApiLandingFooterSection = ({lang, localization}) => {
    return (
        <NewSectionLayoutGrid id={"footer-section"} customMinHeight={"smaller"} customPadding={"first"}>
            <Col grid center>
                <CenterLink to={lang === "hu" ? "/hu" : "/en"}>
                    <NavbarLogo src={logo} alt="Balasys Logo"/>
                </CenterLink>
                <BodyNew color={"new-color"} textAlign="center" marginTop={"small"} smaller>
                    {localization.phone}
                </BodyNew>
                <BodyNew color={"new-color"} textAlign="center" smaller>
                    {localization.email}
                </BodyNew>
                <BodyNew color={"new-color"} textAlign="center" smaller>
                    {localization.address}
                </BodyNew>
            </Col>
        </NewSectionLayoutGrid>
    );
};

export default ApiLandingFooterSection;