import React from "react";

import {BackgroundNew, NewLayoutGrid} from "../styled/grid/Grids";

const NewSectionLayoutGrid = ({children, background, id, start, customPadding, customMinHeight, contact, wide, customGap, solutionsMobile}) => {

    return (
        <BackgroundNew id={id} bg={background}>
            <NewLayoutGrid alignStart={start} customPadding={customPadding} minHeight={customMinHeight} contactMargin={contact} wide={wide} customGap={customGap} solutionsMobile={solutionsMobile}>
                {children}
            </NewLayoutGrid>
        </BackgroundNew>
    )
}

export default NewSectionLayoutGrid;