import React from 'react';

import {Header2New2} from "../styled/typography/Typography";
import {ButtonNewLink} from "../styled/button/ButtonComponents";
import {CtaCardContainer} from "../styled/cards/CTACard.styled";

const CtaCard = ({title, button_text}) => {
    return (
        <CtaCardContainer>
            <Header2New2 light color={"white"}>{title}</Header2New2>
            <ButtonNewLink to={"#api-header-section"}>{button_text}</ButtonNewLink>
        </CtaCardContainer>
    );
};

export default CtaCard;