import React from 'react';
import {ListCardContainer} from "../styled/cards/ListCard.styled";
import {Body} from "../styled/typography/Typography";

const ListCard = ({item, number}) => {
    return (
        <ListCardContainer>
            <div>{number}</div>
            <Body color={"menu-secondary-bg"}>{item}</Body>
        </ListCardContainer>
    );
};

export default ListCard;