import React from 'react';
import {graphql} from "gatsby";

import Layout from "../../components/layouts/Layout";
import ApiLandingHeaderSection from "../../components/sections/ApiLandingHeaderSection";
import ApiLandingFooterSection from "../../components/sections/ApiLandingFooterSection";
import ApiLandingMiddleSection from "../../components/sections/ApiLandingMiddleSection";

const LandingTemplate = ({data}) => {

    const {api_landing, localizations} = data

    return (
        <Layout lang={api_landing.locale} seo={api_landing.seo} noLayout>
            <ApiLandingHeaderSection
                title={api_landing.header.title}
                description={api_landing.header.description}
                illustration={api_landing.header.illustration.url}
                list_token={api_landing.header.list_token}
                lang={api_landing.locale}
                slug={api_landing.slug}
                form_fields={localizations.form_fields}
            />
            <ApiLandingMiddleSection
                illustration={api_landing.middle_section.illustration.url}
                title={api_landing.middle_section.title}
                cards={api_landing.middle_section.cards}
                list={api_landing.middle_section.list}
                cta={api_landing.middle_section.CTA}
            />
            <ApiLandingFooterSection
                lang={api_landing.locale}
                localization={localizations.light_footer}
            />
        </Layout>
    );
};

export const query = graphql`
   query GetSingleApiLandingTemplate($locale: String, $slug: String) {
     api_landing: strapiApiLandingTemplates(locale: {eq: $locale}, slug: {eq: $slug}) {
        locale
        slug
        header {
            description
            illustration {
              url
            }
            list_token
            title
        }
        middle_section {
            cards {
              title
              description
              icon {
                url
              }
            }
            title
            list {
              item
            }
            illustration {
              url
            }
            CTA {
              title
              button_text
            }
        }
        seo {
             title
             isIndexable
             description
             keywords
             preview {
               url
             }
        }
     }
     localizations: strapiLocalizations(locale: {eq: $locale}) {
        form_fields {
          privacy_policy_text
          phone
          last_name
          id
          first_name
          email
          country
          company
          button_text
        }
        light_footer {
            phone
            email
            address
        }
      }
   }
`

export default LandingTemplate;