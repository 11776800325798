import React from 'react';
import parse from "html-react-parser";

import {Body, Header3} from "../styled/typography/Typography";
import {NewCardContainer} from "../styled/cards/NewCard.styled";

const NewCard = ({icon, title, description}) => {
    return (
        <NewCardContainer>
            <img src={icon} alt={"icon"}/>
            <Header3 color={"secondary-brand"} no="true">
                {parse(title)}
            </Header3>
            <Body color={"balasys-50"} smaller="true">
                {parse(description)}
            </Body>
        </NewCardContainer>
    );
};

export default NewCard;